
/*  delete everything above sir */
.App {
  text-align: center;
  background-color: #282c34;
  display: flex;
  min-height: 100vh;
  height: fill-available;
  height: -webkit-fill-available;
  flex-direction: column;
}


header {
  background-color: black;
}
footer {
  margin-top:10%;
  background-color: black;
  height: 10%;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  font-size: 10pt;
}
footer p {
  margin:0;
}
main {
  background-color: #282c34;
  height: 100%; /* takes the visible area of the "main" div */
  margin-bottom:20%;
  border-bottom: 1px solid;}

.home-page-image {
  margin-top: 10px;
}
.home-page-video {
  margin-top: 10px;
  max-width: 90%;
  margin-left:1%;
  margin-right:1%;
}

/* header */

.navbar-nav {
  padding:1%;
  width:94%!important;
}

.nav-link {
  color: white!important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

/* registration page */
.registration-container {
  color:white;
  width:90%;
  margin-left: 5%;
  margin-right: 5%;
}

.registerText {
  text-align: left;
}

.bold {
 font-weight: bold;
}

.center {
  text-align: center!important;
}

@media screen and (max-width: 769px) {
  .registerText {
    font-size: 10pt;
  }
}

.zeffyThings1{
  position:relative;
  overflow:hidden;
  width:100%;
  height:500px;
  max-width: 600px;
  padding-top: 500px;
  margin-top: 2%;
  left:50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 769px) {
  .zeffyThings1 {
    margin-bottom:20%;
  }
}

.zeffyThings2{
  position: absolute;
  border: 0;
  top:0;
  left:0;
  bottom:0;
  right:0;
  width:100%;
  height:100%;
}

/* contact us page*/
.contact-us-section {
  background-color: rgb(34, 33, 33);
  border-radius: 15px;
  color: white;
  display: block;
  width:90%;
  margin-left:5%;
  padding:5%;

}

.important-txt {
  color: rgb(255, 30, 0)!important;
  font-weight: bold;
}

.FaqContainer {
  height:100%;
  background-color: #282c34;
  width: 90%;
  margin-left:5%;
  margin-bottom:20%;
}
.FaqAnswers {
  white-space: pre-wrap;
  text-align: left;
}
/* These are tags for the home page */
.homeContainer {
  padding-bottom: 10%;
  max-height: 30%;
}
.homeContainer img{
  max-width: 750px;
  width:100%;
}

.homeContainer h6 {
  color: white;
}

.homePageButton {
  margin:1%;
}
.promoContainer {
 display: flex;
 flex-flow: row wrap;
 justify-content: center;
}
@media (max-width: 600px) {
  .promoVideo {
    flex: 1 1 auto;
  }
}
.promoVideo {

}

.register-btn {
  background-color: rgb(255, 30, 0)!important;
  font-weight: bold!important;
}

@media screen and (max-width: 769px) {
  .homeContainer {
      flex-direction: column;
      padding-bottom: 20%;
  }
}
/* .accordion{
  padding-bottom:600px;
  background-color: #282c34;

}

.accordion-button {
 
  background-color: #3f5277 !important;
  font-weight: bold !important;
  color: white !important;
  text-align: center!important;
}
.accordion-item {
  background-color: #282c34 !important;
  color: white !important;  */
/* } */